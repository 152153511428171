var exports = {};
exports = {
  A: "ie",
  B: "edge",
  C: "firefox",
  D: "chrome",
  E: "safari",
  F: "opera",
  G: "ios_saf",
  H: "op_mini",
  I: "android",
  J: "bb",
  K: "op_mob",
  L: "and_chr",
  M: "and_ff",
  N: "ie_mob",
  O: "and_uc",
  P: "samsung",
  Q: "and_qq",
  R: "baidu",
  S: "kaios"
};
export default exports;